import { useLocation as useWouterLocation, useRoute as useWouterRoute } from 'wouter';
import kebabCase from 'lodash/kebabCase';

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

const useLocation = () => {
	const [location, setLocation] = useWouterLocation();
	return [location, setLocation, window.location.search];
};

const useRoute = (pattern) => {
	let [match, params] = useWouterRoute(pattern);
	if (match) {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const queryParams = Object.fromEntries(urlSearchParams.entries());
		params = {
			...queryParams,
			...params,
		};
	}
	return [match, params];
}

const generatePath = (path, obj) => {
	Object.keys(obj).forEach((key) => {
		while (path.indexOf(`:${key}`) > -1) {
			const value = obj[key];
			path = path.replace(`:${key}`, value);
		}
	});
	return path;
}

const generateSlug = ({ name, id }) => {
	return `${kebabCase(name)}-${id}`
}

export { useLocation, useRoute, classNames, generatePath, generateSlug };
