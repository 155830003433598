import Home from './Home';
import Profile from './Profile';
import Packs from './Packs';
import Publishers from './Publishers';
import ArtPacks from './ArtPacks';
import ArtPackDetails from './ArtPackDetails';
import ErrorPage from './ErrorPage';
import Asset from './Asset';
import About from './About';
import Plugins from './Plugins';
import Roadmap from './Roadmap';
import NotYetImplemented from './NotYetImplemented';
import Error from './Error';
import Account from './Account';
import ROUTES from './routes';


export default ROUTES;
export {
	Home,
	Packs,
	Publishers,
	ArtPacks,
	ArtPackDetails,
	Profile,
	ErrorPage,
	Asset,
	About,
	Plugins,
	Roadmap,
	NotYetImplemented,
	Account,
	Error,
};
