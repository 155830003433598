import PropTypes from 'prop-types';
import { Fragment, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
	Bars3Icon,
	Cog6ToothIcon,
	DocumentDuplicateIcon,
	UserIcon,
	UsersIcon,
	XMarkIcon,
	HeartIcon,
	CubeIcon,
	GlobeAltIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import ROUTES from '../routes';
import { Link, useRoute } from 'wouter';

import RegionSelect from './RegionSelect';
import { classNames } from '../utils';
import { useAuth } from '../auth';

import PrefabbyLogo from '../assets/images/PrefabbyLogo.png';

const NavLink = ({ href, icon: Icon, children }) => {
	const [isActive] = useRoute(href);
	return (
		<Link href={href} className={classNames(isActive ? 'bg-gray-50 text-indigo-600' : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold')}>
			<Icon
				className={classNames(isActive ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600', 'h-6 w-6 shrink-0')}
				aria-hidden="true"
			/>
			{children}
		</Link>
	);
}

NavLink.propTypes = {
	href: PropTypes.string.isRequired,
	icon: PropTypes.object.isRequired,
	children: PropTypes.string.isRequired,
};

const MenuItem = ({ href, children }) => (
	<Menu.Item>
		{({ active }) => (
			<Link href={href} className={classNames(active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-gray-900')}>{children}</Link>
		)}
	</Menu.Item>
);

MenuItem.propTypes = {
	href: PropTypes.string.isRequired,
	children: PropTypes.string.isRequired,
};

const MenuItemExternalLink = ({ href, children }) => (
	<Menu.Item>
		{({ active }) => (
			<a href={href} className={classNames(active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-gray-900')}>{children}</a>
		)}
	</Menu.Item>
);

MenuItemExternalLink.propTypes = {
	href: PropTypes.string.isRequired,
	children: PropTypes.string.isRequired,
};

const Container = ({ children }) => {
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const { user } = useAuth();

	return (
		<>
			<div>
				<Transition.Root show={sidebarOpen} as={Fragment}>
					<Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
						<Transition.Child
							as={Fragment}
							enter="transition-opacity ease-linear duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="transition-opacity ease-linear duration-300"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<div className="fixed inset-0 bg-gray-900/80" />
						</Transition.Child>

						<div className="fixed inset-0 flex">
							<Transition.Child
								as={Fragment}
								enter="transition ease-in-out duration-300 transform"
								enterFrom="-translate-x-full"
								enterTo="translate-x-0"
								leave="transition ease-in-out duration-300 transform"
								leaveFrom="translate-x-0"
								leaveTo="-translate-x-full"
							>
								<Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
									<Transition.Child
										as={Fragment}
										enter="ease-in-out duration-300"
										enterFrom="opacity-0"
										enterTo="opacity-100"
										leave="ease-in-out duration-300"
										leaveFrom="opacity-100"
										leaveTo="opacity-0"
									>
										<div className="absolute left-full top-0 flex w-16 justify-center pt-5">
											<button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
												<span className="sr-only">Close sidebar</span>
												<XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
											</button>
										</div>
									</Transition.Child>
									{/* Sidebar component, swap this element with another sidebar if you like */}
									<div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
										<div className="flex h-16 shrink-0 items-center">
											<img
												className="h-8 w-auto"
												src={PrefabbyLogo}
												alt="Prefabby"
											/>
										</div>
										<nav className="flex flex-1 flex-col">
											<RegionSelect className="w-full mb-10" />

											<ul role="list" className="flex flex-1 flex-col gap-y-7">
												<li>
													<div className="text-xs font-semibold leading-6 text-gray-400">User</div>
													<ul role="list" className="-mx-2 space-y-1">
														<NavLink href={ROUTES.INDEX} icon={UserIcon}>Your account</NavLink>
														<NavLink href={ROUTES.TEAMS} icon={UsersIcon}>Teams</NavLink>
													</ul>
												</li>
												<li>
													<div className="text-xs font-semibold leading-6 text-gray-400">Content</div>
													<ul role="list" className="-mx-2 mt-2 space-y-1">
														<NavLink href={ROUTES.YOUR_ASSETS} icon={CubeIcon}>Your assets</NavLink>
														<NavLink href={ROUTES.YOUR_FAVORITES} icon={HeartIcon}>Your favorites</NavLink>
														<NavLink href={ROUTES.YOUR_COLLABORATIONS} icon={DocumentDuplicateIcon}>Your collaborations</NavLink>
													</ul>
												</li>
												<li className="mt-auto">
													<NavLink href={ROUTES.PUBLISHERS} icon={GlobeAltIcon}>Publishers</NavLink>
													<NavLink href={ROUTES.SETTINGS} icon={Cog6ToothIcon}>Settings</NavLink>
												</li>
											</ul>
										</nav>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</Dialog>
				</Transition.Root>

				{/* Static sidebar for desktop */}
				<div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
					{/* Sidebar component, swap this element with another sidebar if you like */}
					<div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
						<div className="flex h-16 shrink-0 items-center">
							<img
								className="h-8 w-auto"
								src={PrefabbyLogo}
								alt="Prefabby"
							/>
						</div>
						<nav className="flex flex-1 flex-col">
							<ul role="list" className="flex flex-1 flex-col gap-y-7">
								<li>
									<div className="text-xs font-semibold leading-6 text-gray-400">User</div>
									<ul role="list" className="-mx-2 space-y-1">
										<NavLink href={ROUTES.INDEX} icon={UserIcon}>Your account</NavLink>
										<NavLink href={ROUTES.TEAMS} icon={UsersIcon}>Teams</NavLink>
									</ul>
								</li>
								<li>
									<div className="text-xs font-semibold leading-6 text-gray-400">Content</div>
									<ul role="list" className="-mx-2 mt-2 space-y-1">
										<NavLink href={ROUTES.YOUR_ASSETS} icon={CubeIcon}>Your assets</NavLink>
										<NavLink href={ROUTES.YOUR_FAVORITES} icon={HeartIcon}>Your favorites</NavLink>
										<NavLink href={ROUTES.YOUR_COLLABORATIONS} icon={DocumentDuplicateIcon}>Your collaborations</NavLink>
									</ul>
								</li>
								<li className="mt-auto">
									<NavLink href={ROUTES.PUBLISHERS} icon={GlobeAltIcon}>Publishers</NavLink>
									<NavLink href={ROUTES.SETTINGS} icon={Cog6ToothIcon}>Settings</NavLink>
								</li>
							</ul>
						</nav>
					</div>
				</div>

				<div className="lg:pl-72">
					<div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
						<button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
							<span className="sr-only">Open sidebar</span>
							<Bars3Icon className="h-6 w-6" aria-hidden="true" />
						</button>

						<div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
							<div className="flex flex-1"></div>
							<div className="flex items-center gap-x-4 lg:gap-x-6">
								<RegionSelect className="hidden lg:block w-60" />

								<div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200" aria-hidden="true" />

								<Menu as="div" className="relative">
									<Menu.Button className="-m-1.5 flex items-center p-1.5 mr-6">
										<span className="sr-only">Open user menu</span>
										<img
											className="h-8 w-8 rounded-full bg-gray-50"
											src={user?.picture}
											alt=""
										/>
										<span className="hidden lg:flex lg:items-center">
											<span className="ml-1 text-sm font-semibold leading-6 text-gray-900 whitespace-nowrap" aria-hidden="true">
											</span>
											<ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
										</span>
									</Menu.Button>
									<Transition
										as={Fragment}
										enter="transition ease-out duration-100"
										enterFrom="transform opacity-0 scale-95"
										enterTo="transform opacity-100 scale-100"
										leave="transition ease-in duration-75"
										leaveFrom="transform opacity-100 scale-100"
										leaveTo="transform opacity-0 scale-95"
									>
										<Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
											<MenuItem href={ROUTES.INDEX}>Your account</MenuItem>
											<MenuItemExternalLink href="/api/logout">Logout</MenuItemExternalLink>
										</Menu.Items>
									</Transition>
								</Menu>
							</div>
						</div>
					</div>

					<main className="py-10">
						<div className="px-4 sm:px-6 lg:px-8">
							{children}
						</div>
					</main>
				</div>
			</div>
		</>
	)
};

Container.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.arrayOf(PropTypes.node),
	]),
};

export default Container;
