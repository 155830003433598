import PrefabbyLogo from '../assets/images/PrefabbyLogo.png';

const NotYetImplemented = () => (
	<div className="my-20 h-full w-full text-center">
		<img className="w-20 h-20 mb-6 mx-auto" src={PrefabbyLogo} alt="Prefabby" />
		<p className="text-base text-gray-700">Not yet implemented, hold tight!</p>
	</div>
);

export default NotYetImplemented;
