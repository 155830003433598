import { useState, useEffect } from 'react';
import { Link } from 'wouter';
import { useLocation } from '../utils';
import ROUTES from '../routes';

import PrefabbyLogo from '../assets/images/PrefabbyLogo.png';

const Error = () => {
	const [, , query] = useLocation();
	const [params, setParams] = useState(Object.fromEntries(new URLSearchParams(query)));

	useEffect(() => {
		if (query) {
			setParams(Object.fromEntries(new URLSearchParams(query)));
		}
	}, [query]);

	if (params?.sc === '404') {
		return (
			<div className="h-screen w-screen text-center flex flex-col justify-center">
				<img className="w-20 h-20 mb-6 mx-auto" src={PrefabbyLogo} alt="Prefabby" />
				<p className="text-base text-gray-700 font-medium mb-3">
					Not found!
				</p>
				<p className="text-base text-gray-700">
					The link you entered doesn&apos;t seem to exist on Prefabby.<br/>
					Please <Link href={ROUTES.INDEX}>click here</Link> to start over.
				</p>
			</div>
		);
	} else if (params?.sc === '500') {
		return (
			<div className="h-screen w-screen text-center flex flex-col justify-center">
				<img className="w-20 h-20 mb-6 mx-auto" src={PrefabbyLogo} alt="Prefabby" />
				<p className="text-base text-gray-700 font-medium mb-3">
					Oh snap! It broke!
				</p>
				<p className="text-base text-gray-700">
					Sometimes things just don&apos;t work as intended.<br/>
					Please let me know at matt@prefabby.com!
				</p>
			</div>
		);
	} else {
		return (
			<div className="h-screen w-screen text-center flex flex-col justify-center">
				<img className="w-20 h-20 mb-6 mx-auto" src={PrefabbyLogo} alt="Prefabby" />
				<p className="text-base text-gray-700 font-medium mb-3">
					Something unexpected happened!
				</p>
				<p className="text-base text-gray-700">
					Seems like you ran into an unspecified error.<br/>
					Please let me know at matt@prefabby.com!
				</p>
			</div>
		);
	}
};

export default Error;
