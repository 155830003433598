import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import { Switch as HeadlessUiSwitch } from '@headlessui/react';
import { classNames } from '../utils';

const Switch = ({ name, ...props }) => {
	const { isSubmitting } = useFormikContext();
	const [_, meta, helpers] = useField(name);
	const { setValue } = helpers;

	return (
		<HeadlessUiSwitch
			name={name}
			checked={meta.value}
			onChange={setValue}
			disabled={isSubmitting || props.disabled}
			className={classNames(
				meta.value ? 'bg-indigo-600' : 'bg-gray-200',
				'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
			)}
		>
			<span className="sr-only">Use setting</span>
			<span
				aria-hidden="true"
				className={classNames(
					meta.value ? 'translate-x-5' : 'translate-x-0',
					'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
				)}
			/>
		</HeadlessUiSwitch>
	)
};

Switch.propTypes = {
	name: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
};

export default Switch;
