import { createContext, useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import axios from '../api';

const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState();

	useEffect(() => {
		const loadUserDetailsAsync = async () => {
			const { data } = await axios.get('/users/me');
			setUser(data.data);
			setLoading(false);
		};
		loadUserDetailsAsync();
	}, []);

	const value = {
		user,
		isLoggedIn: !!user,
		loading,
		setUser,
	};

	return (
		<AuthContext.Provider value={value}>
			{children}
		</AuthContext.Provider>
	);
}

AuthProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.arrayOf(PropTypes.node),
	]),
};

const useAuth = () => {
	return useContext(AuthContext);
};

export default AuthProvider;
export { useAuth };
