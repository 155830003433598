import { Link } from "wouter";
import { useRoute } from "../utils";
import ROUTES from "./routes";

/*
	DONE:

	Office
	TropicalJungle
	Dogs
	Nightclubs
	Apocalypse,
	BattleRoyale,
	City,
	CityCharacters,  --- TODO screenshot

	SHORT:

	AncientEmpire,
	Construction,
	Dungeons,
	DungeonRealms,
	Explorers,		--- TODO screenshot
	HeroCharacters,
	FantasyKingdom,
	Farm,
	GangWarfare,
	Hearse,
	Heist,
	HorrorMansion,
	Kids,
	Military,
	Nature,
	ParticleFX,
	Pirates,
	SciFiCity,
	SciFiSpace,
	SciFiWorlds,
	Shops,
	Spy,			--- TODO screenshot
	StreetRacer,
	Town,
	War,
	Western,
	WesternFrontier

	TODO:

*/

const catalogue = {
	Hearse: {
		title: 'POLYGON Hearse',
		subtitle: 'A hearse and a coffin. Mixes great with the City, Town and Horror Mansion packs!',
		content: () => (
			<div className="prose prose-indigo mx-auto text-gray-500 lg:max-w-none">
				<p>
					This classic hearse was originally offered as a reward to Synty jam participants. It is now available exclusively on the Synty Store.
				</p>
			</div>
		),
		syntyStoreLink: 'https://syntystore.com/products/polygon-hearse-kit',
	},
	GangWarfare: {
		title: 'POLYGON Gang Warfare',
		subtitle: 'A pack with everything you need to build your own drug cartel, mafia or other crime business themed game.',
		assetStoreLink: 'https://assetstore.unity.com/packages/3d/environments/industrial/polygon-gang-warfare-low-poly-3d-art-by-synty-150589',
		videoId: 'lbvB-AKY5xM',
	},
	Farm: {
		title: 'POLYGON Farm',
		subtitle: 'A huge pack with everything you need for a farm themed game.',
		assetStoreLink: 'https://assetstore.unity.com/packages/3d/environments/industrial/polygon-farm-low-poly-3d-art-by-synty-146192',
		videoId: '9cIyZDSAWXM',
	},
	FantasyKingdom: {
		title: 'POLYGON Fantasy Kingdom',
		subtitle: 'An epic pack to create a medieval or fantasy themed game.',
		assetStoreLink: 'https://assetstore.unity.com/packages/3d/environments/fantasy/polygon-fantasy-kingdom-low-poly-3d-art-by-synty-164532',
		videoId: 'Y3127q0GBRs',
	},
	HeroCharacters: {
		title: 'POLYGON Modular Fantasy Hero Characters',
		subtitle: 'A pack to create highly customizable fantasy characters with 720 modular pieces.',
		assetStoreLink: 'https://assetstore.unity.com/packages/3d/characters/humanoids/fantasy/polygon-modular-fantasy-hero-characters-low-poly-3d-art-by-synty-143468',
		videoId: 'vM4fSXvXHfY',
	},
	Explorers: {
		title: 'POLYGON Explorer Kit',
		subtitle: 'A small pack with two explorer characters, a 4x4 and some props.',
		assetStoreLink: 'https://assetstore.unity.com/packages/3d/characters/humanoids/humans/polygon-explorer-low-poly-3d-art-by-synty-128996',
		videoId: null,
	},
	DungeonRealms: {
		title: 'POLYGON Dungeon Realms',
		subtitle: 'An epic 1000+ prefabs pack for modular fantasy dungeons.',
		assetStoreLink: 'https://assetstore.unity.com/packages/3d/environments/dungeons/polygon-dungeon-realms-low-poly-3d-art-by-synty-189093',
		videoId: 'RpH851L0SCU',
	},
	Construction: {
		title: 'POLYGON Construction',
		subtitle: 'A huge, modular pack for high rise and house construction scenes with all the power tools you would ever need!',
		assetStoreLink: 'https://assetstore.unity.com/packages/3d/environments/industrial/polygon-construction-low-poly-3d-art-by-synty-168036',
		videoId: 'SatE5dlog5s',
	},
	CityCharacters: {
		title: 'POLYGON City Characters',
		subtitle: 'Add more diversity to your cities with this character pack!',
		content: () => (
			<div className="prose prose-indigo mx-auto text-gray-500 lg:max-w-none">
				<p>
					With 19 new characters, you can bring some more life into your scene, for example:
				</p>
				<ul role="list">
					<li>Biker</li>
					<li>Grandma</li>
					<li>Fire Fighter</li>
					<li>Hipster</li>
					<li>Shop Keeper</li>
					<li>...</li>
				</ul>
				<p>
					Who would not want to have a walking hot dog in their city?
				</p>
			</div>
		),
		assetStoreLink: 'https://assetstore.unity.com/packages/3d/characters/humanoids/humans/polygon-city-characters-low-poly-3d-art-by-synty-106757',
		videoId: null,
	},
	City: {
		title: 'POLYGON City',
		subtitle: 'The city art pack contains modular buildings, streets and props for a vibrant city atmosphere.',
		content: () => (
			<div className="prose prose-indigo mx-auto text-gray-500 lg:max-w-none">
				<p>
					With this pack, you can build vibrant cities with residential and commercial buildings. Thanks to the modular style, you can build as high as you like: the sky is the limit.
					Four different textures offer a variety of styles for buildings, characters and vehicles.
				</p>
				<ul role="list">
					<li>76 building parts (Modular Offices, Apartments,...)</li>
					<li>9 characters (Police, Business People,...)</li>
					<li>65 environment prefabs (Roads, Bridges, Subway Entries,...)</li>
					<li>174 props (Air Conditioning, Signs, Billboards,...)</li>
					<li>8 cars (Ambulance, Police, Taxi, Van,...)</li>
				</ul>
				<p>
					Note that the buildings are not supposed to be entered.
				</p>
			</div>
		),
		assetStoreLink: 'https://assetstore.unity.com/packages/3d/environments/urban/polygon-city-low-poly-3d-art-by-synty-95214',
		videoId: '2259hfOJiZc',
	},
	BattleRoyale: {
		title: 'POLYGON Battle Royale',
		subtitle: 'Who will be Last Man Standing?',
		content: () => (
			<div className="prose prose-indigo mx-auto text-gray-500 lg:max-w-none">
				<p>
					Inspired by popular battle royale games, this pack gives you everything you need to build such a game in a modern military setting.
					It comes with 24 alternative texture colors.
				</p>
				<ul role="list">
					<li>15 characters in 4 colors (Sniper, Goth, Mercenary,...)</li>
					<li>Character attachments (Hair, Hats, Helments,...)</li>
					<li>Buildings (Guard Tower, Warehouse, Tents,...)</li>
					<li>10 vehicles incl. destroyed versions (Tanks, Cars, Trucks,...)</li>
					<li>19 ranged weapons with 28 skins (RPG, Assault Rifles, Pistols,...)</li>
					<li>7 melee weapons (Fire Axe, Machete, Baseball Bat,...)</li>
					<li>Environment prefabs (Roads, Rocks, Clouds,...)</li>
				</ul>
				<p>

				</p>
			</div>
		),
		assetStoreLink: 'https://assetstore.unity.com/packages/3d/environments/urban/polygon-battle-royale-low-poly-3d-art-by-synty-128513',
		videoId: '5LwG-kH3nBY',
	},
	Apocalypse: {
		title: 'POLYGON Apocalypse',
		subtitle: 'When the world comes to an end, you better be prepared!',
		content: () => (
			<div className="prose prose-indigo mx-auto text-gray-500 lg:max-w-none">
				<p>
					Apocalypse is a versatile pack which is perfect for any catastrophic scenario, from virus outbreak to zombie apocalypse. But it&apos;s more than that!
					With the removable overgrowth and exchangable clean or dirty textures it also fits well for a regular downtown scenario or a cozy camping site setting.
				</p>
				<ul role="list">
					<li>671 props (Barricades, Rubbish, Camping Equipment,...)</li>
					<li>30 complete buildings (Apartment, Cafe, Church,...)</li>
					<li>150 modular building pieces (Ceilings, Fences, Doors,...)</li>
					<li>24 additional structures (Bridge, Cooling Towers, Military Tents,...)</li>
					<li>240 environment prefabs (Roads, Tunnels, Storm Canals,...)</li>
					<li>93 items (Bottles, Ammo, Bags,...)</li>
					<li>86 weapons (SMGs, LMGs, Molotov Cocktail,...)</li>
					<li>184 modular weapon pieces (Barrels, Handles, Scopes,...)</li>
					<li>17 vehicles (Ambulance, Camper, Motorbikes,...)</li>
					<li>88 vehicle attachments (Armor Plating, Spikes,...)</li>
					<li>30 characters (Zombies, Homeless, Biker,...)</li>
					<li>86 character attachments (Armors, Backpacks, Beards,...)</li>
				</ul>
				<p>
					This pack is <strong>huge</strong> and a must have!
				</p>
			</div>
		),
		assetStoreLink: 'https://assetstore.unity.com/packages/3d/environments/urban/polygon-apocalypse-low-poly-3d-art-by-synty-154193',
		videoId: 'pcHaQRKuQqo',
	},
	Nightclubs: {
		title: 'POLYGON Nightclubs',
		subtitle: 'Get the party started with this versatile build 2.0 art pack.',
		content: () => (
			<div className="prose prose-indigo mx-auto text-gray-500 lg:max-w-none">
				<p>
					The Nightclubs pack consists of different club and pub assets. You&apos;ll find everything you need from exclusive roof top restaurants to karaoke bars.
				</p>
				<ul role="list">
					<li>583 props (Drinks, Lights, Benches,...)</li>
					<li>140 building pieces (Walls, Pergolas, Curtains,...)</li>
					<li>8 characters (Bartender, Party People,...)</li>
					<li>26 character attachments (Beards, Earrings, Hats,...)</li>
					<li>41 environment prefabs (Skydome, Background Buildings,...)</li>
					<li>18 FX (Spotlights, Lasers, Fog,...)</li>
				</ul>
				<p>
					You can feel the beats pumping just by looking at this vivid pack!
				</p>
			</div>
		),
		assetStoreLink: 'https://assetstore.unity.com/packages/3d/environments/urban/polygon-nightclubs-low-poly-3d-art-by-synty-216721',
		videoId: 'ezSRTsP2mmM',
	},
	Office: {
		title: 'POLYGON Office',
		subtitle: 'Should you ever need a stapler, you\'ll find it here!',
		content: () => (
			<div className="prose prose-indigo mx-auto text-gray-500 lg:max-w-none">
				<p>
					This pack has everything you could ever need for an office themed map: reception, open space, manager office, toilets, server room, stairwell - it&apos;s all accounted for, if you allow the pun!
				</p>
				<ul role="list">
					<li>626 props (Computers, Kitchen Parts,...)</li>
					<li>128 building pieces (Walls, Floors, Ceilings,...)</li>
					<li>18 characters (Office Workers, Cleaners, Security,...)</li>
				</ul>
				<p>
					Grab a NERF gun and join the office fun!
				</p>
			</div>
		),
		assetStoreLink: 'https://assetstore.unity.com/packages/3d/props/interior/polygon-office-low-poly-3d-art-by-synty-159492',
		videoId: 'dowpyLcAFnY',
	},
	TropicalJungle: {
		title: 'POLYGON Tropical Jungle - Nature Biomes',
		subtitle: 'Embed your game into a beautiful tropical jungle.',
		content: () => (
			<div className="prose prose-indigo mx-auto text-gray-500 lg:max-w-none">
				<p>
					This biome art pack is perfect to convey the feeling of heat and humidity of a jungle in your game. No matter whether you build a holiday resort or a pirate treasure island, POLYGON Tropical Jungle has you covered!
				</p>
				<ul role="list">
					<li>135 environment prefabs (Trees, Bushes, Waterfalls, Cliffs,...)</li>
					<li>13 buildings (Docks, Temple Ruins, Rope Bridge,...)</li>
					<li>27 props (Shipwreck, Barrels, Crates,...)</li>
					<li>16 terrain materials (Grass, Sands, Rocks,...)</li>
					<li>2 vehicles (Raft, Canoe)</li>
					<li>26 particle systems (Birds, Fish, Smoke,...)</li>
				</ul>
				<p>
					Don&apos;t forget to pack your sunscreen!
				</p>
			</div>
		),
		assetStoreLink: 'https://assetstore.unity.com/packages/3d/environments/fantasy/polygon-tropical-jungle-nature-biomes-low-poly-3d-art-by-synty-234253',
		videoId: 'v44r1K5_V70',
	},
	Dogs: {
		title: 'POLYGON - Dog Pack',
		subtitle: 'Add some more life to your game with this fantastic low poly dog pack.',
		content: () => (
			<div className="prose prose-indigo mx-auto text-gray-500 lg:max-w-none">
				<p>
					This pack features a total of 18 different dogs, including scifi robot and zombie styles. The pack comes with plenty of attachments and - contrary to other packs - this one comes fully animated!
				</p>
				<ul role="list">
					<li>18 dog models (German Shepherd, Husky, Wolf, Robot,...)</li>
					<li>28 attachments (Saddle, Gas Mask, Vests, Armour,...)</li>
					<li>4 FX (Poop, Pee,...)</li>
					<li>44 props (Balls, Bones, Dog House,...)</li>
					<li>77 animations (Walking, Barking, Peeing,...)</li>
				</ul>
				<p>
					So many details! It&apos;s obvious that this pack was designed by dog lovers!
				</p>
			</div>
		),
		assetStoreLink: 'https://assetstore.unity.com/packages/3d/characters/animals/mammals/polygon-dog-pack-low-poly-3d-art-by-synty-192790',
		videoId: 'dtZEuMhV4go',
	},
	Heist: {
		title: 'POLYGON Heist',
		subtitle: 'From piles of cash to jewelry, from modular bank building parts to vault elements, from clerks to gansters - everything you need for your heist game.',
		assetStoreLink: 'https://assetstore.unity.com/packages/3d/environments/urban/polygon-heist-low-poly-3d-art-by-synty-97949',
		videoId: 'AU3mDuUCrmE',
	},
	HorrorMansion: {
		title: 'POLYGON Horror Mansion',
		subtitle: 'Everything you need to build a haunted mansion or a spooky hotel. Works well for less creepy and more cozy scenes, too!',
		content: () => (
			<div className="prose prose-indigo mx-auto text-gray-500 lg:max-w-none">
				<p>I got strong Maniac Mansion or The Colonel&apos;s Bequest vibes from this one!</p>
			</div>
		),
		assetStoreLink: 'https://assetstore.unity.com/packages/3d/environments/fantasy/polygon-horror-mansion-low-poly-3d-art-by-synty-213346',
		videoId: 'bbylcM2ZrMQ',
	},
	Kids: {
		title: 'POLYGON Kids',
		subtitle: 'The first art pack which complements the otherwise adult characters from other packs: including a huge set of environment, props and face plates!',
		assetStoreLink: 'https://assetstore.unity.com/packages/3d/characters/humanoids/humans/polygon-kids-low-poly-3d-art-by-synty-180629',
		videoId: 'g9fgt0Vr5Ig',
	},
	Military: {
		title: 'POLYGON Military',
		subtitle: 'A massive (1500+ prefabs) art pack for military themed games.',
		assetStoreLink: 'https://assetstore.unity.com/packages/3d/environments/historic/polygon-military-low-poly-3d-art-by-synty-176304',
		videoId: 'UYp4E-I12-Q',
	},
	Nature: {
		title: 'POLYGON Nature',
		subtitle: 'This art pack contains props for different vegation styles and textures and shaders to work with regular Unity terrain.',
		assetStoreLink: 'https://assetstore.unity.com/packages/3d/vegetation/trees/polygon-nature-low-poly-3d-art-by-synty-120152',
		videoId: 'NozlRTxMsq4',
	},
	ParticleFX: {
		title: 'POLYGON Particle FX',
		subtitle: 'A huge collection of Unity particle FX, for smoke, magic spells, explosions, and much more!',
		assetStoreLink: 'https://assetstore.unity.com/packages/vfx/particles/polygon-particle-fx-low-poly-3d-art-by-synty-168372',
		videoId: 'Y3az8jkS0FU',
	},
	Pirates: {
		title: 'POLYGON Pirates',
		subtitle: 'A large art pack with environment props for a variety of pirate style themes, including - of course - pirates and ships. Arrr!',
		assetStoreLink: 'https://assetstore.unity.com/packages/3d/environments/historic/polygon-pirates-low-poly-3d-art-by-synty-92579',
		videoId: '4qbD3gviGM0',
	},
	SciFiCity: {
		title: 'POLYGON Sci-Fi City',
		subtitle: 'If Syndicate and Blade Runner had a baby, it would probably look like this large art pack for sci-fi themed games.',
		assetStoreLink: 'https://assetstore.unity.com/packages/3d/environments/sci-fi/polygon-sci-fi-city-low-poly-3d-art-by-synty-115950',
		videoId: 'E-bGLtv6zHY',
	},
	SciFiSpace: {
		title: 'POLYGON Sci-Fi Space',
		subtitle: 'Everything you need for space ships and space stations.',
		assetStoreLink: 'https://assetstore.unity.com/packages/3d/environments/sci-fi/polygon-sci-fi-space-low-poly-3d-art-by-synty-138857',
		videoId: 'KKsODOPx94s',
	},
	SciFiWorlds: {
		title: 'POLYGON Sci-Fi Worlds',
		subtitle: 'A great pack to colonize a new planet! With three different factions and building types, this pack is great for a sci-fi RTS.',
		assetStoreLink: 'https://assetstore.unity.com/packages/3d/environments/sci-fi/polygon-sci-fi-worlds-low-poly-3d-art-by-synty-206299',
		videoId: 'NqmtBFo0LXs',
	},
	Shops: {
		title: 'POLYGON Shops',
		subtitle: 'This huge (1900+ prefabs) art pack is tailored to build various shops and malls.',
		assetStoreLink: 'https://assetstore.unity.com/packages/3d/environments/urban/polygon-shops-pack-low-poly-3d-art-by-synty-199026',
		videoId: '9VLkEVzFHOI',
	},
	Spy: {
		title: 'POLYGON Spy',
		subtitle: 'This small contains two spy characters, a spy car, and various props.',
		assetStoreLink: 'https://assetstore.unity.com/packages/3d/vehicles/polygon-spy-kit-low-poly-3d-art-by-synty-143396',
		videoId: null,
	},
	StreetRacer: {
		title: 'POLYGON Street Racer',
		subtitle: 'This large (1000+ prefabs) art contains all that\'s needed for a street car racing game with modular cars and engine upgrades. Plus, it all takes place on a huge container port which is included!',
		assetStoreLink: 'https://assetstore.unity.com/packages/3d/vehicles/land/polygon-street-racer-low-poly-3d-art-by-synty-194800',
		videoId: 'zmvm8Co484A',
	},
	Town: {
		title: 'POLYGON Town',
		subtitle: 'A collection set of fixed and modular houses, lots of props and environment, nine characters and many more assets for a vibrant town scenario.',
		assetStoreLink: 'https://assetstore.unity.com/packages/3d/environments/urban/polygon-town-pack-low-poly-3d-art-by-synty-121115',
		videoId: '6fd2c5ePT98',
	},
	War: {
		title: 'POLYGON War',
		subtitle: 'A large (550+ prefabs) World War 2 themed art pack with US, British, Aussie/Kiwi, Russian and German soliders and vehicles and plenty of environment, including a massive demo scene featuring Omaha Beach.',
		assetStoreLink: 'https://assetstore.unity.com/packages/3d/environments/historic/polygon-war-low-poly-3d-art-by-synty-106992',
		videoId: '0Ket-RGIYig',
	},
	Western: {
		title: 'POLYGON Western',
		subtitle: 'Everything you need for a Western themed game, including a beautiful steam train.',
		content: () => (
			<div className="prose prose-indigo mx-auto text-gray-500 lg:max-w-none">
				<p>
					This pack works great with the <Link href={`${ROUTES.PACKS}?packs=WesternFrontier`}>Western Frontier</Link> pack.
				</p>
			</div>
		),
		assetStoreLink: 'https://assetstore.unity.com/packages/3d/environments/historic/polygon-western-low-poly-3d-art-by-synty-112212',
		videoId: 'UysVKG7ujgE',
	},
	WesternFrontier: {
		title: 'POLYGON Western Frontier',
		subtitle: 'Settlers, Native Americans, Gold Mines, Wagons, Mexicans,... This pack has it all for the next Oregon Trail clone! Too bad there are no horses included.',
		content: () => (
			<div className="prose prose-indigo mx-auto text-gray-500 lg:max-w-none">
				<p>
					This pack works great with the <Link href={`${ROUTES.PACKS}?packs=Western`}>Western</Link> pack.
				</p>
			</div>
		),
		assetStoreLink: 'https://assetstore.unity.com/packages/3d/environments/historic/polygon-western-frontier-low-poly-3d-art-by-synty-130564',
		videoId: 'uey264PD43M',
	},
	AncientEmpire: {
		title: 'POLYGON Ancient Empire',
		subtitle: 'A large (700+ prefab) "Greet-Roman Fusion" art pack wonderfully suitable for any game based on ancient times.',
		assetStoreLink: 'https://assetstore.unity.com/packages/3d/environments/historic/polygon-ancient-empire-low-poly-3d-art-by-synty-224020',
		videoId: 'U1xg3V8vCvM',
	},
	Dungeon: {
		title: 'POLYGON Dungeon',
		subtitle: 'Everything needed for eery dungeons and caves: interiors, loot and traps, weapons and potions, and several hero and enemy characters.',
		assetStoreLink: 'https://assetstore.unity.com/packages/3d/environments/dungeons/polygon-dungeons-low-poly-3d-art-by-synty-102677',
		videoId: 'ACBH9wTBQbU',
	},
};

const Packs = () => {
	const [_, params] = useRoute("/packs");
	const packs = params.packs?.split(',') || Object.keys(catalogue);

	return (
		<div className="space-y-8">
			{packs.map((packName) => {
				const packDetails = catalogue[packName];
				if (packDetails) {
					return (
						<div key={packName} className="shadow sm:overflow-hidden sm:rounded-md">
							<div className="bg-white space-y-6 p-6 lg:p-8">
								<div className="mx-auto max-w-prose text-base lg:max-w-none">
									<p className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
										{packDetails.title}
									</p>
								</div>
								<div className="relative z-10 mx-auto max-w-prose text-base lg:mx-0 lg:max-w-5xl lg:pr-72">
									<p className="text-lg text-gray-500">
										{packDetails.subtitle}
									</p>
								</div>
								<div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-8">
									<div className="relative z-10">
										{packDetails.content && packDetails.content()}
										<div className="mx-auto mt-10 flex max-w-prose text-base lg:max-w-none">
											{packDetails.assetStoreLink && (
												<div className="rounded-md shadow">
													<a
														href={`${packDetails.assetStoreLink}?aid=1100lrnYB`}
														target="_new"
														className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-5 py-3 text-base font-medium text-white hover:bg-indigo-700"
													>
														Get {packDetails.title}
													</a>
												</div>
											)}
											{packDetails.syntyStoreLink && (
												<div className="rounded-md shadow">
													<a
														href={packDetails.syntyStoreLink}
														target="_new"
														className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-5 py-3 text-base font-medium text-white hover:bg-indigo-700"
													>
														Get {packDetails.title}
													</a>
												</div>
											)}
										</div>
									</div>
									{packDetails.videoId && (
										<div className="relative mx-auto mt-12 max-w-prose text-base lg:mt-0 lg:max-w-none w-full">
											<svg
												className="absolute top-0 right-0 -mt-20 -mr-20 lg:top-auto lg:right-auto lg:bottom-1/2 lg:left-1/2 lg:mt-0 lg:mr-0 xl:top-0 xl:right-0 xl:-mt-20 xl:-mr-20"
												width={404}
												height={384}
												fill="none"
												viewBox="0 0 404 384"
												aria-hidden="true"
											>
												<defs>
													<pattern id="bedc54bc-7371-44a2-a2bc-dc68d819ae60" x={0} y={0} width={20} height={20} patternUnits="userSpaceOnUse">
														<rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
													</pattern>
												</defs>
												<rect width={404} height={384} fill="url(#bedc54bc-7371-44a2-a2bc-dc68d819ae60)" />
											</svg>
											<div className="relative rounded-lg bg-white shadow-lg">
												<div className="rounded-t-lg">
													<div className="aspect-w-16 aspect-h-9 rounded-md overflow-hidden">
														<iframe src={`https://www.youtube-nocookie.com/embed/${packDetails.videoId}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
													</div>
												</div>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					);
				} else {
					return null;
				}
			})}
		</div>
	);
};

export default Packs;
