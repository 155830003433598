// import { Link } from 'wouter';
// import ROUTES from './routes';
import UnityPluginImage1 from './unity-plugin-1.png';
import UnityPluginImage2 from './unity-plugin-2.png';

const UnityPlugin = () => (

	<div className="mb-12">

		<nav className="hidden sm:flex" aria-label="Breadcrumb">
			<ol role="list" className="flex items-center space-x-4">
				<li>
					<div className="flex">
						<span className="text-sm font-medium text-gray-500 hover:text-gray-700">
							Plugins
						</span>
					</div>
				</li>
			</ol>
		</nav>

		<div className="mt-2 md:flex md:items-center md:justify-between mb-12">
			<div className="min-w-0 flex-1">
				<h1 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
					Get the Prefabby plugin for your game engine
				</h1>
				<p className="mt-2 text-base text-gray-700">
					An important building block for Prefabby are plugins for the respective supported game engine. Please note that you need an account and an API key in order to use most of the plugin functionality. You can sign up for an account here, and create your API key here.
				</p>
			</div>
			<div className="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
			</div>
		</div>

		<div className="mt-6 space-y-7 text-base text-gray-700">
			<h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">
				Unity plugin
			</h2>
			<p>
				The Prefabby Unity plugin is available as a package on GitHub. Open the Unity package manager and use &quot;Add package from git URL...&quot;, then enter <code>git@github.com:Prefabby/UnityPlugin.git</code> as the URL.
			</p>
			<img src={UnityPluginImage1} className="w-auto max-w-full" />
			<img src={UnityPluginImage2} className="w-auto max-w-full" />
			<p>
				After the plugin was successfully imported and compiled, you&apos;ll find a new main menu <code>Tools -&gt; Prefabby</code> in Unity.
			</p>
		</div>
	</div>

);

export default UnityPlugin;
