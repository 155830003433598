import { useState, useEffect } from 'react';
import { Formik, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';

import axios from '../../api';
import { Switch, TextField } from '../../components';
import { classNames } from '../../utils';

const AutoGenerateTokens = () => {
	const { values, initialValues, dirty, submitForm } = useFormikContext();

	useEffect(() => {
		if (dirty && (values.useUnityPlugin !== initialValues.useUnityPlugin || values.useUnrealEnginePlugin !== initialValues.useUnrealEnginePlugin)) {
			submitForm();
		}
	}, [dirty, values.useUnityPlugin, initialValues.useUnityPlugin, values.useUnrealEnginePlugin, initialValues.useUnrealEnginePlugin]);

	return null;
};

const validationSchema = Yup.object({
	useUnityPlugin: Yup.bool()
		.required('Required'),
	unityPluginAccessKey: Yup.string(),
	useUnrealEnginePlugin: Yup.bool()
		.required('Required'),
	unrealEnginePluginAccessKey: Yup.string(),
});

const PublishingKeys = () => {
	const [unityPluginToken, setUnityPluginToken] = useState('');

	useEffect(() => {
		const loadTokensAsync = async () => {
			const [{ data: { data: unityResult } }] = await Promise.all([
				axios.get('/tokens/unity')
			]);
			if (unityResult && unityResult.value) {
				setUnityPluginToken(unityResult.value);
			}
		}
		loadTokensAsync();
	}, []);

	const handleSubmit = async (values, { setSubmitting }) => {
		if (values.useUnityPlugin) {
			const { data: { data: result } } = await axios.post('/tokens/unity');
			setUnityPluginToken(result.value);
		} else {
			await axios.delete('/tokens/unity');
			setUnityPluginToken('');
		}
		setSubmitting(false);
	};

	return (
		<section aria-labelledby="publishing-keys-heading">
			<div className="shadow sm:overflow-hidden sm:rounded-md">
				<div className="bg-white py-6 px-4 sm:p-6">
					<div className="mb-8">
						<h2 id="publishing-keys-heading" className="text-lg font-medium leading-6 text-gray-900">
							Publishing Keys
						</h2>
						<p className="mt-1 text-sm text-gray-500">
							To publish your creations right from the editor, you need to:
						</p>
						<ol className="my-2 text-sm text-gray-500 list-decimal list-inside">
							<li>download the Prefabby Unity plugin or Unreal Engine plugin</li>
							<li>activate the corresponding switch</li>
							<li>copy the publishing keys and paste them in the Prefabby plugin settings</li>
						</ol>
					</div>

					<Formik
						initialValues={{
							useUnityPlugin: !!unityPluginToken,
							unityPluginAccessKey: unityPluginToken,
							useUnrealEnginePlugin: false,
							unrealEnginePluginAccessKey: '',
						}}
						enableReinitialize
						validationSchema={validationSchema}
						onSubmit={handleSubmit}
					>
						{({ isSubmitting, values }) => (
							<Form disabled={isSubmitting} className="space-y-6">
								<div className="flex items-center">
									<Switch name="useUnityPlugin" />
									<label
										htmlFor="useUnityPlugin"
										className="ml-2 block text-sm"
									>
										Publish using the Unity plugin
									</label>
								</div>

								<div className={classNames(values.useUnityPlugin ? '' : 'opacity-50')}>
									<TextField label="Unity Plugin Access Key" name="unityPluginAccessKey" readOnly disabled={!values.useUnityPlugin} />
								</div>

								<div className="flex items-center">
									<Switch name="useUnrealEnginePlugin" disabled />
									<label
										htmlFor="useUnrealEnginePlugin"
										className="ml-2 block text-sm"
									>
										Publish using the Unreal Engine plugin
									</label>
								</div>

								<div className={classNames(values.useUnrealEnginePlugin ? '' : 'opacity-50')}>
									<TextField label="Unreal Engine Plugin Access Key" name="unrealEnginePluginAccessKey" disabled />
								</div>

								<AutoGenerateTokens />

							</Form>
						)}
					</Formik>
				</div>
			</div>
		</section>
	);

};

export default PublishingKeys;
