import { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { ClipboardDocumentListIcon } from '@heroicons/react/24/outline';

import { TextField } from '../components';
import axios from '../api';
import { useAuth } from '../auth';

const userDataValidationSchema = Yup.object({
	displayName: Yup.string()
		.required('Required'),
});

const Account = () => {
	const { user, setUser } = useAuth();
	const [apiToken, setApiToken] = useState('');

	useEffect(() => {
		const loadApiTokenAsync = async () => {
			try {
				const { data: { data: result } } = await axios.get('/tokens/unity');
				if (result && result.value) {
					setApiToken(result.value);
				}
			} catch (_) {
				setApiToken(null);
			}
		}
		loadApiTokenAsync();
	}, []);

	const handleSubmit = async (values, { setSubmitting }) => {
		try {
			const { data: { data: result } } = await axios.patch('/users/me', {
				displayName: values.displayName,
			});
			setUser(result);
			toast('Display name succesfully updated!');
		} finally {
			setSubmitting(false);
		}
	};

	const generateApiToken = async (_, { setSubmitting }) => {
		try {
			const { data: { data: result } } = await axios.post('/tokens/unity');
			setApiToken(result.value);
		} finally {
			setSubmitting(false);
		}
		//await axios.delete('/tokens/unity');
	};

	const copyApiToken = async () => {
		if ('clipboard' in navigator) {
			await navigator.clipboard.writeText(apiToken);
		} else {
			document.execCommand('copy', true, apiToken);
		}
		toast('Access Key successfully copied.');
	};

	const RightButton = () => (
		<button
			type="button"
			className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
			onClick={copyApiToken}
			disabled={!apiToken}
		>
			<ClipboardDocumentListIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
			Copy
		</button>
	);

	return (
		<div className="px-4 mx-auto max-w-7xl lg:px-8 space-y-12">
			<Formik
				initialValues={{
					displayName: user?.displayName,
					apiToken,
				}}
				enableReinitialize
				validationSchema={userDataValidationSchema}
				onSubmit={handleSubmit}
			>
				{({ isSubmitting/*, values*/ }) => (
					<Form disabled={isSubmitting}>
						<div className="-mx-4 px-4 py-8 shadow-sm ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lg sm:px-8 sm:pb-14 lg:col-span-2 lg:row-span-2 lg:row-end-2 xl:px-16 xl:pb-20 xl:pt-16">
							<div className="space-y-12">
								<div className="border-b border-gray-900/10 pb-12">
									<h2 className="text-base font-semibold leading-7 text-gray-900">Your account</h2>
									<p className="mt-1 text-sm leading-6 text-gray-600">
										Your display name will be displayed publicly along with your creations or collaborations. Please keep it clean.
									</p>

									<div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
										<div className="sm:col-span-4">
											<TextField
												label="Display name"
												name="displayName"
												disabled={isSubmitting}
											/>
										</div>
									</div>
								</div>
							</div>

							<div className="mt-6 flex items-center justify-end gap-x-6">
								<button
									type="submit"
									className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
								>
									Save
								</button>
							</div>
						</div>
					</Form>
				)}
			</Formik>

			<Formik
				initialValues={{
					apiToken,
				}}
				enableReinitialize
				onSubmit={generateApiToken}
			>
				{({ isSubmitting }) => (
					<Form disabled={isSubmitting}>
						<div className="-mx-4 px-4 py-8 shadow-sm ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lg sm:px-8 sm:pb-14 lg:col-span-2 lg:row-span-2 lg:row-end-2 xl:px-16 xl:pb-20 xl:pt-16">
							<div className="space-y-12">
								<div>
									<h2 className="text-base font-semibold leading-7 text-gray-900">API access</h2>
									<p className="mt-1 text-sm leading-6 text-gray-600">
										In order to publish or collaborate on Prefabby, you need to generate an Access Key.
									</p>
								</div>

								<div>
									<TextField
										label="Access Key"
										name="apiToken"
										disabled={isSubmitting}
										readOnly
										rightButton={RightButton}
									/>
									<div className="flex justify-end">
										<button
											type="submit"
											className="mt-6 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
										>
											{apiToken === '' ? 'Generate' : 'Regenerate'}
										</button>
									</div>
								</div>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);

};

export default Account;
