import PropTypes from 'prop-types';
import { Field } from 'formik';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';

import { classNames } from '../utils';

const TextField = ({ label, type = 'text', name, readOnly, rightButton: RightButton, ...props }) => (
	<Field name={name} {...props}>
		{({ field, meta, form }) => (
			<div>
				<label htmlFor="email" className="block text-sm font-medium text-gray-700">
					{label}
				</label>
				<div className="flex mt-1 rounded-md shadow-sm">
					<div className="relative flex flex-grow items-stretch focus-within:z-10">
						<input
							type={type}
							{...field}
							disabled={form.isSubmitting || props.disabled}
							readOnly={readOnly}
							className={
								classNames(
									'block w-full appearance-none border px-3 py-2 focus:outline-none sm:text-sm',
									RightButton ? 'rounded-l-md' : 'rounded-md',
									meta.touched && meta.error ? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500' : 'border-gray-300 placeholder-gray-400 focus:border-indigo-500 focus:ring-indigo-500'
								)
							}
							aria-invalid={meta.touched && meta.error}
							aria-describedby={`${name}-error`}
						/>
						{meta.touched && meta.error && (
							<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
								<ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
							</div>
						)}
					</div>
					{RightButton && <RightButton />}
				</div>
				{meta.touched && meta.error && (
					<p className="mt-2 text-sm text-red-600" id={`${name}-error`}>
						{meta.error}
					</p>
				)}
			</div>
		)}
	</Field>
);

TextField.propTypes = {
	label: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	rightButton: PropTypes.element
};

TextField.defaultProps = {
	type: 'text'
};

export default TextField;
