const AccountDetails = () => {

	return (
		<section aria-labelledby="account-details-heading">
			<form action="#" method="POST">
				<div className="shadow sm:overflow-hidden sm:rounded-md">
					<div className="bg-white py-6 px-4 sm:p-6">
						<div>
							<h2 id="payment-details-heading" className="text-lg font-medium leading-6 text-gray-900">
								Account details
							</h2>
							<p className="mt-1 text-sm text-gray-500">
								Update your account details. <span className="font-medium">This is work in progress!</span>
							</p>
						</div>

						<div className="mt-6 grid grid-cols-4 gap-6">
							<div className="col-span-4 sm:col-span-2">
								<label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
									First name
								</label>
								<input
									type="text"
									name="first-name"
									id="first-name"
									autoComplete="cc-given-name"
									className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-gray-900 focus:outline-none focus:ring-gray-900 sm:text-sm"
								/>
							</div>

							<div className="col-span-4 sm:col-span-2">
								<label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
									Last name
								</label>
								<input
									type="text"
									name="last-name"
									id="last-name"
									autoComplete="cc-family-name"
									className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-gray-900 focus:outline-none focus:ring-gray-900 sm:text-sm"
								/>
							</div>

							<div className="col-span-4 sm:col-span-2">
								<label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
									Email address
								</label>
								<input
									type="text"
									name="email-address"
									id="email-address"
									autoComplete="email"
									className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-gray-900 focus:outline-none focus:ring-gray-900 sm:text-sm"
								/>
							</div>

						</div>
					</div>
					<div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
						<button
							type="submit"
							className="inline-flex justify-center rounded-md border border-transparent bg-gray-800 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2"
						>
							Save
						</button>
					</div>
				</div>
			</form>
		</section>
	);

};

export default AccountDetails;
