import { useState, useEffect } from 'react';
import { Link } from 'wouter';

import { generatePath, generateSlug } from '../utils';
import axios from '../api';
import ROUTES from './routes';

const Publishers = () => {
	const [publishers, setPublishers] = useState([]);

	useEffect(() => {
		const loadPublishersAsync = async () => {
			const { data: { data: publishers } } = await axios.request('/artpack/publisher');
			setPublishers(publishers);
		};
		loadPublishersAsync();
	}, []);

	return (
		<div className="px-4 mx-auto max-w-7xl lg:px-8 space-y-12">

			<nav className="hidden sm:flex" aria-label="Breadcrumb">
				<ol role="list" className="flex items-center space-x-4">
					<li>
						<div className="flex">
							<span className="text-sm font-medium text-gray-500 hover:text-gray-700">
								Publishers
							</span>
						</div>
					</li>
				</ol>
			</nav>

			<div className="md:flex md:items-center md:justify-between">
				<div className="min-w-0 flex-1">
					<h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
						Supported Publishers
					</h2>
					<p className="mt-2 text-base text-gray-700">
						The following art pack publishers are currently supported. Click on one of them to see the supported art packs. If you are interested in seeing a specific publisher listed here, or if you are a publisher who would like to be listed, please get in touch.
					</p>
				</div>
				<div className="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
				</div>
			</div>

			<div className="flow-root">
				<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
						<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
							<table className="min-w-full divide-y divide-gray-300">
								<thead className="bg-gray-50">
									<tr>
										<th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
											Name
										</th>
										<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
											Link
										</th>
										<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
											<span className="sr-only">Show packs</span>
										</th>
									</tr>
								</thead>
								<tbody className="divide-y divide-gray-200 bg-white">
									{publishers.map((publisher) => {
										const publisherSlug = generatePath(
											ROUTES.PUBLISHER_ART_PACKS,
											{
												publisherSlug: generateSlug({ name: publisher.name, id: publisher.id }),
											},
										);
										return (
											<tr key={publisher.id}>
												<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
													{publisher.name}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													<a href={`${publisher.url}?ref=prefabby`} target="_new">{publisher.url}</a>
												</td>
												<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
													<Link className="text-indigo-600 hover:text-indigo-900" href={publisherSlug}>
														Show packs<span className="sr-only"> for art pack publisher {publisher.name}</span>
													</Link>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>

		</div>
	);

}

export default Publishers;
