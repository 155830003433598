import { useState, useEffect } from 'react';
import { LinkIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { Link } from 'wouter';

import axios from '../api';
import { generatePath, generateSlug, useRoute } from '../utils';
import ROUTES from "./routes";

const ArtPacks = () => {
	const [publisher, setPublisher] = useState();
	const [artPacks, setArtPacks] = useState([]);
	const [_, params] = useRoute(ROUTES.PUBLISHER_ART_PACKS);

	useEffect(() => {
		const loadPublisherAndArtPacksAsync = async () => {
			const publisherId = params.publisherSlug.substring(params.publisherSlug.lastIndexOf('-') + 1);
			const [
				{ data: { data: publisher } },
				{ data: { data: artPacks } },
			] = await Promise.all([
				axios.request(`/artpack/publisher/${publisherId}` ),
				axios.request('/artpack', { params: { publisherId } }),
			]);
			setPublisher(publisher);
			setArtPacks(artPacks);
		};
		loadPublisherAndArtPacksAsync();
	}, []);

	return (
		<div className="px-4 mx-auto max-w-7xl lg:px-8 space-y-12">

			<nav className="hidden sm:flex" aria-label="Breadcrumb">
				<ol role="list" className="flex items-center space-x-4">
					<li>
						<div className="flex">
							<Link href={ROUTES.PUBLISHERS} className="text-sm font-medium text-gray-500 hover:text-gray-700">
								Publishers
							</Link>
						</div>
					</li>
					<li>
						<div className="flex items-center">
							<ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
							<span className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
								{publisher?.name}
							</span>
						</div>
					</li>
				</ol>
			</nav>

			<div className="md:flex md:items-center md:justify-between">
				<div className="min-w-0 flex-1">
					<h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
						{publisher?.name}
					</h2>
					<p className="mt-2 text-base text-gray-700">
						The following art packs are currently supported. Click on the Details link for more information. If you are missing a pack, please get in touch.
					</p>
				</div>
				<div className="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
					<a href={`${publisher?.url}?ref=prefabby`} target="_new" className="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
						Visit Publisher
						<LinkIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
					</a>
				</div>
			</div>

			<div className="flow-root">
				<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
						<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
							<table className="min-w-full divide-y divide-gray-300">
								<thead className="bg-gray-50">
									<tr>
										<th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
											Name
										</th>
										<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
											<span className="sr-only">Details</span>
										</th>
									</tr>
								</thead>
								<tbody className="divide-y divide-gray-200 bg-white">
									{artPacks.map((artPack) => (
										<tr key={artPack.id}>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
												{artPack.name} {artPack.markAsNew && <span className="ml-1 inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">NEW</span>}
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right">
												<Link
													href={generatePath(ROUTES.ART_PACK_DETAILS, { publisherSlug: params.publisherSlug, artPackSlug: generateSlug({ name: artPack.name, id: artPack.id })})}
													className="text-indigo-600 hover:text-indigo-900 font-medium"
												>
													Details
												</Link>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>

		</div>
	);

}

export default ArtPacks;
