import { Link } from 'wouter';
import ROUTES from './routes';

const About = () => (

	<>
		<h1 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight mb-12">
			About Prefabby
		</h1>
		<div className="grid grid-cols-1 gap-y-16 lg:grid-cols-2 lg:grid-rows-[auto_1fr] lg:gap-y-12 mb-12">
			<div className="lg:pl-20">
				<img src="https://via.placeholder.com/512.png?text=Here+goes+a+fancy+image" className="aspect-square rotate-3 rounded-2xl bg-gray-100 object-cover" />
			</div>
			<div className="lg:order-first">
				<h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">
					Make the most out of prefabs
				</h2>
				<div className="mt-6 space-y-7 text-base text-gray-700">
					<p>
						Thousands of hobbyist game developers and small indie studios are using art packs with prefabricated game objects, so-called prefabs. Several <Link href={ROUTES.PUBLISHERS} className="text-indigo-500 font-medium">publishers</Link> have established themselves and provide a vast range of reusable prefabs with a consistent art style and within a reasonable budget.
					</p>
					<p>
						But even with this incredible aid, building a nice and natural looking arrangements of prefabs is an art and requires skill and a lot of time. Not every developer is able or willing to create nice looking scenes. The number of games reusing entire demo maps from professional publisher&apos;s art packs prove the point.
					</p>
					<p>
						As we&apos;re using the same assets anyway, why not share our creations, or reuse something that others already created? A fully furnished building from the <Link href={`${ROUTES.PACKS}?packs=Town`} className="text-indigo-500 font-medium">Town pack</Link>, an armed space ship or a space station from the <Link href={`${ROUTES.PACKS}?packs=SciFiSpace`} className="text-indigo-500 font-medium">Sci-Fi Space pack</Link>,... What works for one person in their simulator game could be helpful to another in a shooter.
					</p>
					<p>
						Prefabby is a platform to share, download and remix creations built out of prefabs.
					</p>
				</div>
			</div>
		</div>
		<div className="grid grid-cols-1 gap-y-16 lg:grid-cols-2 lg:grid-rows-[auto_1fr] lg:gap-y-12 mb-12">
			<div className="lg:pl-20">
				<img src="https://via.placeholder.com/512.png?text=Here+goes+a+fancy+image" className="aspect-square -rotate-2 rounded-2xl bg-gray-100 object-cover" />
			</div>
			<div className="lg:order-first">
				<h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">
					Collaborate in real-time
				</h2>
				<div className="mt-6 space-y-7 text-base text-gray-700">
					<p>
						In game engines like Unity and Godot, which are often used by amateur and indie developers, real-time collaborative map editing is either not supported natively or requires additional tooling. Unity, for example, had several offerings which all evolved around version control and merging each contributor&apos;s changes iteratively, lacking real-time collaboration. To make matters worse, their offering continuously changed over time, making it even harder to follow and adopt. Godot doesn&apos;t offer native collaboration functionality presently, let alone real-time.
					</p>
					<p>
						Working with prefabs is a prime use case for real-time collaboration. Provided that all participants have the same art packs installed and use these prefabs exclusively, the number of possible actions that need to be synchronized between participants is limited.
					</p>
					<p>
						Prefabby allows to collaborate on scene sub-trees built out of prefabs, in real-time.
					</p>
				</div>
			</div>
		</div>
		<div className="grid grid-cols-1 gap-y-16 lg:grid-cols-2 lg:grid-rows-[auto_1fr] lg:gap-y-12 mb-12">
			<div className="lg:pl-20">
				<img src="https://via.placeholder.com/512x256.png?text=Here+goes+a+fancy+image" className="aspect-square rotate-3 rounded-2xl bg-gray-100 object-cover" />
			</div>
			<div className="lg:order-first">
				<h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">
					Work across engines
				</h2>
				<div className="mt-6 space-y-7 text-base text-gray-700">
					<p>
						Sometimes creativity is all about jamming together! Why not edit the same creation in Unity, Godot, and other possible engines, at the same time?
					</p>
					<p>
						Prefabby initially provides plugins for Unity and Godot, but I am hoping to add support for Unreal at some point in the future and I am open to other requests.
					</p>
				</div>
			</div>

		</div>

		<div className="gap-y-16 lg:gap-y-12 mb-12">
			<h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">
				Restrictions
			</h2>
			<div className="mt-6 space-y-7 text-base text-gray-700">
				<p>
					Since Prefabby is built to work with prefabs exclusively, it comes with some restrictions. You can read about it in the README of the respective engine plugin.
				</p>
			</div>
		</div>

		<div className="gap-y-16 lg:gap-y-12 mb-12">
			<h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">
				Roadmap
			</h2>
			<div className="mt-6 space-y-7 text-base text-gray-700">
				<p>
					You can find the roadmap <Link href={ROUTES.ROADMAP} className="text-indigo-500 font-medium">here</Link>.
				</p>
			</div>
		</div>

	</>

);

export default About;
