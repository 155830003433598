import { useState, useEffect } from 'react';
import { Link } from 'wouter';
import { HeartIcon as HeartSolidIcon } from '@heroicons/react/20/solid';
import { HeartIcon as HeartOutlineIcon } from '@heroicons/react/24/outline';
import { CloudArrowDownIcon } from '@heroicons/react/20/solid';
import { useRoute, classNames, generatePath, generateSlug } from '../utils';
import { useAuth } from '../auth';
import axios from '../api';
import ROUTES from "./routes";

const Asset = () => {
	const [_, params] = useRoute(ROUTES.ASSET);
	const [asset, setAsset] = useState();
	const { isLoggedIn } = useAuth();

	useEffect(() => {
		const loadAssetAsync = async () => {
			const { data: { data: asset } } = await axios.get(`/storefront/assets/${params.assetId}`);
			console.log('asset = ', asset);
			setAsset(asset);
		};
		if (params.assetId !== asset?.id) {
			loadAssetAsync();
		}
	}, [params?.assetId]);

	const markAsFavorite = async () => {
		const { data: { data: asset } } = await axios.post(`/storefront/assets/${params.assetId}/favorite`);
		setAsset(asset);
	};

	const unmarkAsFavorite = async () => {
		const { data: { data: asset } } = await axios.post(`/storefront/assets/${params.assetId}/unfavorite`);
		setAsset(asset);
	};

	const primary = true;

	if (asset) {
		return (
			<div className="lg:grid lg:auto-rows-min lg:grid-cols-12 lg:gap-x-8">

				<div className="lg:col-span-5 lg:col-start-8 flex">
					<div className="grow">
						<h1 className="text-xl font-medium text-gray-900">{asset.title}</h1>
						<p className="my-1 text-sm text-gray-500">by {asset.creatorDisplayName}</p>
					</div>
					<div className="flex items-center">
						<div className="flex items-center mr-4">
							<CloudArrowDownIcon className="h-5 w-5 text-gray-400 mr-2" title="Number of downloads" aria-hidden="true" />
							<span className="sr-only">Number of downloads</span>
							<span className="text-sm text-gray-400">{asset.numberOfDownloads}</span>
						</div>
						<div className="flex items-center">
							<HeartSolidIcon className="h-5 w-5 text-gray-400 mr-2" title="Number of favorites" aria-hidden="true" />
							<span className="sr-only">Number of favorites</span>
							<span className="text-sm text-gray-500">{asset.numberOfFavorites}</span>
						</div>
					</div>
				</div>

				<div className="mt-8 lg:col-span-7 lg:col-start-1 lg:row-span-3 lg:row-start-1 lg:mt-0">
					<h2 className="sr-only">Images</h2>
					<div className="grid grid-cols-1 lg:grid-cols-2 lg:grid-rows-3 lg:gap-8">
						<div
							className={classNames(
								primary ? 'lg:col-span-2 lg:row-span-2' : 'hidden lg:block',
								'relative'
							)}
						>
							<img
								src={asset.previewImageUrl}
								alt={`A screenshot of ${asset.title}`}
								className="rounded-lg"
							/>
							<div className="absolute inset-x-0 top-0 flex h-full items-start justify-end overflow-hidden rounded-lg p-4">
								<div aria-hidden="true" className="absolute inset-x-0 bottom-0 h-36 bg-gradient-to-t text-gray-500 from-current opacity-50"></div>
								{isLoggedIn && (
									<button
										type="button"
										className={
											classNames(
												'inline-flex justify-center rounded-md border px-4 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
												asset.favorite ? 'border-transparent bg-indigo-500 text-white' : 'border-gray-300 bg-white hover:bg-gray-50 text-gray-700'
											)
										}
										onClick={asset.favorite ? unmarkAsFavorite : markAsFavorite}
										title={asset.favorite ? 'Remove from favorites' : 'Add to favorites'}
									>
										{asset.favorite ? <HeartSolidIcon className="h-5 w-5 text-white" aria-hidden="true" /> : <HeartOutlineIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />}
										<span className="sr-only">Mark as favorite</span>
									</button>
								)}
							</div>
						</div>
					</div>
				</div>

				<div className="mt-8 lg:col-span-5">

					<div>
						<h2 className="text-sm font-medium text-gray-900">Description</h2>
						<div className="prose prose-sm mt-4 text-gray-500">{asset.description}</div>
					</div>

					<div className="mt-10">
						<h2 className="text-sm font-medium text-gray-900">Required packs</h2>
						<ul className="mt-4 flex flex-wrap">
							{asset.requiredArtPacks?.map((pack) => {
								const artPackSlug = generateSlug({ name: pack.name, id: pack.id });
								const publisherSlug = generateSlug({ name: pack.publisherName, id: pack.publisherId });
								return (
									<li key={pack.id} className="mr-1 mb-1 text-gray-500 text-sm">
										<Link href={generatePath(ROUTES.ART_PACK_DETAILS, { publisherSlug, artPackSlug })} className="text-indigo-500 font-medium">
											{pack.name}
										</Link>
										{' '}by{' '}
										<Link href={generatePath(ROUTES.PUBLISHER_ART_PACKS, { publisherSlug })} className="text-indigo-500 font-medium">{pack.publisherName}</Link>
									</li>
								);
							})}
						</ul>
					</div>

				</div>

			</div>
		);
	} else {
		return null;
	}
};

export default Asset;
