const ROUTES = {
	INDEX: '/',
	PROFILE: '/profile',
	PROFILE_ACCOUNT_DETAILS: '/account-details',
	PROFILE_PUBLISHING_KEYS: '/publishing-keys',
	ASSET: '/asset/:assetId',
	ABOUT: '/about',
	PLUGINS: '/plugins',
	ROADMAP: '/about/roadmap',

	TEAMS: '/teams',
	YOUR_ASSETS: '/your-assets',
	YOUR_COLLABORATIONS: '/your-collaborations',
	YOUR_FAVORITES: '/your-favorites',
	PUBLISHERS: '/publishers',
	PUBLISHER_ART_PACKS: '/publishers/:publisherSlug',
	ART_PACK_DETAILS: '/publishers/:publisherSlug/:artPackSlug',
	SETTINGS: '/settings',

};

export default ROUTES;
