import { useState, useEffect } from 'react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { Link } from 'wouter';

import axios from '../api';
//import { ArtPackLink } from '../components';
import { useRoute, generatePath } from '../utils';
import ROUTES from "./routes";

const ArtPackDetails = () => {
	const [publisher, setPublisher] = useState();
	const [artPack, setArtPack] = useState();
	const [_, params] = useRoute(ROUTES.ART_PACK_DETAILS);

	useEffect(() => {
		const loadPublisherAndArtPackAsync = async () => {
			const publisherId = params.publisherSlug.substring(params.publisherSlug.lastIndexOf('-') + 1);
			const artPackId = params.artPackSlug.substring(params.artPackSlug.lastIndexOf('-') + 1);
			const [
				{ data: { data: publisher } },
				{ data: { data: artPack } },
			] = await Promise.all([
				axios.request(`/artpack/publisher/${publisherId}`),
				axios.request(`/artpack/${artPackId}`),
			]);
			setPublisher(publisher);
			setArtPack(artPack);
		};
		loadPublisherAndArtPackAsync();
	}, []);

	return (
		<div className="px-4 mx-auto max-w-7xl lg:px-8 space-y-12">

			<nav className="hidden sm:flex" aria-label="Breadcrumb">
				<ol role="list" className="flex items-center space-x-4">
					<li>
						<div className="flex">
							<Link href={ROUTES.PUBLISHERS} className="text-sm font-medium text-gray-500 hover:text-gray-700">
								Publishers
							</Link>
						</div>
					</li>
					<li>
						<div className="flex items-center">
							<ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
							<Link href={generatePath(ROUTES.PUBLISHER_ART_PACKS, { publisherSlug: params.publisherSlug })} className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
								{publisher?.name}
							</Link>
						</div>
					</li>
					<li>
						<div className="flex items-center">
							<ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
							<span className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
								{artPack?.name}
							</span>
						</div>
					</li>
				</ol>
			</nav>

			<div className="md:flex md:items-center md:justify-between">
				<div className="min-w-0 flex-1">
					<h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
						{artPack?.name}
					</h2>
					<p className="mt-2 text-base text-gray-700">
						You can get this pack from the following sources:
					</p>
					<ul className="list-none md:list-disc md:list-inside">
						{artPack?.links.map((link, index) => (
							<li key={`${index}`} className="my-2">
								<a href={link.url} target="_blank" rel="noreferrer" className="text-indigo-500 font-medium">
									{link.linkType === 'Unity' && "On the Unity Asset Store"}
									{link.linkType === 'Unreal' && "On the Unreal Engine Marketplace"}
									{link.linkType === 'Publisher' && "On the publisher's homepage"}
								</a>
							</li>
						))}
					</ul>
				</div>
				<div className="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
				</div>
			</div>

		</div>
	);

}

export default ArtPackDetails;
