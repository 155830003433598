import PropTypes from 'prop-types';
import { useRouter, useLocation, Router } from 'wouter';

const Scope = (props) => {
	const router = useRouter();
	const [parentLocation] = useLocation();

	const nestedBase = `${router.base}${props.base}`;

	// don't render anything outside of the scope
	if (!parentLocation.startsWith(nestedBase)) {
		return null;
	}

	// we need key to make sure the router will remount when base changed
	return (
		<Router base={nestedBase} key={nestedBase}>
			{props.children}
		</Router>
	);
};

Scope.propTypes = {
	base: PropTypes.string.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.arrayOf(PropTypes.node),
	]),
};

export default Scope;
