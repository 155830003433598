import { useEffect } from 'react';
import { Route, Link, useRoute, useLocation } from 'wouter';
import { KeyIcon, UserCircleIcon } from '@heroicons/react/24/outline';

import { classNames } from '../utils';
import ROUTES from './routes';
import { AccountDetails, PublishingKeys } from './profile/';

const subNavigation = [
	{ name: 'Account Details', href: ROUTES.PROFILE_ACCOUNT_DETAILS, icon: UserCircleIcon, key: 'account' },
	{ name: 'Publishing Keys', href: ROUTES.PROFILE_PUBLISHING_KEYS, icon: KeyIcon, key: 'keys' },
];

const Profile = () => {
	const [match] = useRoute();
	const [location, redirect] = useLocation();

	useEffect(() => {
		if (match) {
			redirect(ROUTES.PROFILE_ACCOUNT_DETAILS);
		}
	}, [match, redirect]);

	return (
		<main className="mx-auto max-w-7xl pb-10 lg:py-12 lg:px-8">
			<div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
				<aside className="py-6 px-2 sm:px-6 lg:col-span-3 lg:py-0 lg:px-0">
					<nav className="space-y-1">
						{subNavigation.map((item) => (
							<Link
								key={item.key}
								href={item.href}
								className={classNames(
									location === item.href
										? 'bg-gray-50 text-indigo-600 hover:bg-white'
										: 'text-gray-900 hover:text-gray-900 hover:bg-gray-50',
									'group rounded-md px-3 py-2 flex items-center text-sm font-medium'
								)}
								aria-current={location === item.href ? 'page' : undefined}
							>
								<item.icon
									className={classNames(
										location === item.href ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500',
										'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
									)}
									aria-hidden="true"
								/>
								<span className="truncate">{item.name}</span>
							</Link>
						))}
					</nav>
				</aside>

				<div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
					<Route path={ROUTES.PROFILE_ACCOUNT_DETAILS} component={AccountDetails} />
					<Route path={ROUTES.PROFILE_PUBLISHING_KEYS} component={PublishingKeys} />
				</div>
			</div>
		</main>
	);
};

export default Profile;
