import { useState, useEffect } from 'react';
import { Switch, Route } from 'wouter';
import { CookiesProvider } from 'react-cookie';
import { Toaster } from 'react-hot-toast';

import { set403Handler } from './api';
import { AuthProvider, useAuth } from './auth';
import { Container, Scope } from './components';
import ROUTES, { Packs, Publishers, ArtPacks, Profile, ErrorPage, Asset, About, Plugins, ArtPackDetails, Roadmap, NotYetImplemented, Account, Error } from './routes';

import PrefabbyLogo from './assets/images/PrefabbyLogo.png';
import './App.scss';

const AUTH0_AUTHZ_URL = '/api/oauth2/authorization/auth0';

const Loader = () => {
	const { user, loading } = useAuth();
	const [redirect, setRedirect] = useState(false);

	useEffect(() => {
		set403Handler((_error) => {
			window.location.href = AUTH0_AUTHZ_URL;
		});
	}, [])

	useEffect(() => {
		if (!loading && !user) {
			setRedirect(true);
		}
	}, [loading, user]);

	if (redirect) {
		window.location.href = AUTH0_AUTHZ_URL;
		return null;
	} else if (loading) {
		return (
			<div className="h-full w-full flex flex-1 justify-center items-center">
				<img className="w-20 h-20 animate-pulse" src={PrefabbyLogo} alt="Prefabby" />
			</div>
		);
	} else {
		return (
			<Container>
				<Switch>
					<Route path={ROUTES.INDEX} component={Account} />
					<Route path={ROUTES.YOUR_ASSETS} component={NotYetImplemented} />
					<Route path={ROUTES.YOUR_FAVORITES} component={NotYetImplemented} />
					<Route path={ROUTES.YOUR_COLLABORATIONS} component={NotYetImplemented} />
					<Route path={ROUTES.TEAMS} component={NotYetImplemented} />
					<Route path={ROUTES.SETTINGS} component={NotYetImplemented} />

					<Route path={ROUTES.ABOUT} component={About} />
					<Route path={ROUTES.ROADMAP} component={Roadmap} />
					<Route path={ROUTES.PLUGINS} component={Plugins} />
					<Route path={"/packs"} component={Packs} />
					<Route path={ROUTES.PUBLISHERS} component={Publishers} />
					<Route path={ROUTES.PUBLISHER_ART_PACKS} component={ArtPacks} />
					<Route path={ROUTES.ART_PACK_DETAILS} component={ArtPackDetails} />
					<Route path={ROUTES.ASSET} component={Asset} />
					<Scope base={ROUTES.PROFILE}>
						<Profile />
					</Scope>
					<Route path="/:rest*" component={ErrorPage} />
				</Switch>
			</Container>
		);
	}
};

const App = () => (
	<CookiesProvider>
		<Toaster />
		<AuthProvider>
			<Switch>
				<Route path="/error" component={Error} />
				<Route>
					<Loader />
				</Route>
			</Switch>
		</AuthProvider>
	</CookiesProvider>
);

export default App;
