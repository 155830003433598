import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { Link } from 'wouter';

import ROUTES from './routes';

const Roadmap = () => (
	<>

		<nav className="hidden sm:flex" aria-label="Breadcrumb">
			<ol role="list" className="flex items-center space-x-4">
				<li>
					<div className="flex">
						<Link href={ROUTES.ABOUT} className="text-sm font-medium text-gray-500 hover:text-gray-700">
							About
						</Link>
					</div>
				</li>
				<li>
					<div className="flex items-center">
						<ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
						<span className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
							Roadmap
						</span>
					</div>
				</li>
			</ol>
		</nav>

		<div className="mt-2 md:flex md:items-center md:justify-between mb-10">
			<div className="min-w-0 flex-1">
				<h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
					Roadmap
				</h2>
				<p className="mt-2 text-base text-gray-700">
					Below is the Trello board with ideas, tasks and bugs for Prefabby.
				</p>
			</div>
			<div className="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
			</div>
		</div>

		<iframe src="https://trello.com/b/zUAMNkqc.html" width="100%" height="1000">
		</iframe>

	</>
);

export default Roadmap;
