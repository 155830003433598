import PropTypes from 'prop-types';
import { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import ReactCountryFlag from 'react-country-flag';

import { classNames } from '../utils';

const regions = process.env.NODE_ENV === 'production' ?
	window.location.host === 'devel.prefabby.com' ? [
		{ key: 'fsn', countryCode: 'DE', label: 'Devel' },
	] : [
		{ key: 'fsn', countryCode: 'DE', label: 'Falkenstein (Germany)' },
		{ key: 'syd', countryCode: 'AU', label: 'Sydney (Australia)' },
	]
: [
	{ key: 'fsn', countryCode: 'DE', label: 'Local' },
];

const RegionSelect = ({ className = 'w-60' }) => {
	const [selected] = useState(() => {
		if (process.env.NODE_ENV === 'development' || window.location.host === 'devel.prefabby.com') {
			return regions[0];
		} else {
			const key = window.location.host.match(/(.*)\.app\.prefabby\.com/)[1];
			return regions.find((region) => region.key === key);
		}
	});

	const handleChange = (region) => {
		if (region !== selected) {
			window.location.host = `${region.key}.app.prefabby.com`;
		}
	};

	return (
		<Listbox value={selected} onChange={handleChange}>
			{({ open }) => (
				<>
					<Listbox.Label className="sr-only">Region</Listbox.Label>
					<div className={classNames('relative', className)}>
						<Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
							<span className="flex items-center">
								<ReactCountryFlag countryCode={selected.countryCode} svg />
								<span className="ml-3 block truncate">{selected.label}</span>
							</span>
							<span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
								<ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
							</span>
						</Listbox.Button>

						<Transition
							show={open}
							as={Fragment}
							leave="transition ease-in duration-100"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
								{regions.map((region) => (
									<Listbox.Option
										key={region.key}
										className={({ active }) => classNames(active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9')}
										value={region}
									>
										{({ selected, active }) => (
											<>
												<div className="flex items-center">
													<ReactCountryFlag countryCode={region.countryCode} svg />
													<span className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}>
														{region.label}
													</span>
												</div>
												{selected && (
													<span className={classNames(active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4')}>
														<CheckIcon className="h-5 w-5" aria-hidden="true" />
													</span>
												)}
											</>
										)}
									</Listbox.Option>
								))}
							</Listbox.Options>
						</Transition>
					</div>
				</>
			)}

		</Listbox>
	);
}

RegionSelect.propTypes = {
	className: PropTypes.string,
};

export default RegionSelect;
